@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  @apply w-4 h-4 bg-primary border cursor-pointer rounded-full;
}

.custom-thumb::-moz-range-thumb {
  @apply w-4 h-4 bg-primary border cursor-pointer rounded-full;
}

h1 {
  @apply text-3xl font-bold tracking-tight sm:text-4xl text-center;
}

h2 {
  @apply text-2xl font-bold tracking-tight sm:text-3xl text-center;
}

h3 {
  @apply text-xl font-semibold tracking-tight sm:text-2xl text-center;
}

h4 {
  @apply text-lg font-semibold tracking-tight sm:text-xl text-center;
}
